import type { RegisteredTheme } from '$const/theme';
import { writable } from 'svelte/store';
import { localhostKey, themes } from '$const/theme';
import { browser } from "$app/environment"

// Do not change this. Daisyui required.
export const DAISIUI_KEY = 'data-theme' as const;

const { subscribe, set } = writable<RegisteredTheme | null>(null);

export const theme = {
	subscribe,
	init(): void {
		if(!browser) return
		const stored = localStorage.getItem(localhostKey) as RegisteredTheme;
		if (!stored || !themes.includes(stored)) {
			this.unset();
			return;
		}
		this.set(stored);
	},
	set(theme: RegisteredTheme): void {
		document.documentElement.setAttribute(DAISIUI_KEY, theme);
		localStorage.setItem(localhostKey, theme);
		set(theme);
	},
	unset(): void {
		document.documentElement.removeAttribute(DAISIUI_KEY);
		localStorage.removeItem(localhostKey);
		set(null);
	}
};
