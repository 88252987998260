<script lang="ts">
	import { isExternal } from '$internal/utils/url';
	import External from './ExternalIcon.svelte';

	export let href: string;
	export let title: string = href;
	export let rel = '';
	export let blank = false;
	export let fullreload = true

	let external = false;

	/**
	 * Inject noopener norefeerer in external link
	 */
	if (isExternal(href)) {
		external = true;
		let _rel = new Set(rel.split(' '));
		_rel.add('noopener');
		_rel.add('noreferrer');
		rel = Array.from(_rel).join(' ');
		blank = true;
	}
</script>

<a {href} {title} {rel} {...$$props} target={blank ? '_blank' : ''} data-sveltekit-reload={fullreload}>
	{#if external}
		<span class="inline-block inherit">
			<span class="flex items-center gap-1 w-max">
				<slot />
				<External class="w-4" />
			</span>
		</span>
	{:else}
		<slot />
	{/if}
</a>

<style>
	.inherit {
		text-decoration: inherit;
	}
</style>
