<svg
	xmlns="http://www.w3.org/2000/svg"
	viewBox="0 0 24 24"
	version="1.1"
	width="100%"
	height="100%"
    {...$$props}
	style="shape-rendering:geometricPrecision; text-rendering:geometricPrecision; image-rendering:optimizeQuality; fill-rule:evenodd; clip-rule:evenodd"
	xmlns:xlink="http://www.w3.org/1999/xlink"
>
	<g
		><path
			style="opacity:0.961"
			fill="currentColor"
			d="M 2.5,2.5 C 5.57186,2.18259 8.57186,2.51592 11.5,3.5C 9.25731,4.47423 6.92397,4.80756 4.5,4.5C 4.5,9.16667 4.5,13.8333 4.5,18.5C 9.16667,18.5 13.8333,18.5 18.5,18.5C 18.1924,16.076 18.5258,13.7427 19.5,11.5C 20.4841,14.4281 20.8174,17.4281 20.5,20.5C 14.5,20.5 8.5,20.5 2.5,20.5C 2.5,14.5 2.5,8.5 2.5,2.5 Z"
		/></g
	>
	<g
		><path
			style="opacity:0.709"
			fill="currentColor"
			d="M 13.5,2.5 C 15.8333,2.5 18.1667,2.5 20.5,2.5C 20.8076,4.92397 20.4742,7.25731 19.5,9.5C 18.8076,8.97473 18.3076,8.30806 18,7.5C 15.1424,11.2138 11.6424,13.8805 7.5,15.5C 9.11951,11.3576 11.7862,7.85762 15.5,5C 14.244,4.58309 13.5774,3.74975 13.5,2.5 Z"
		/></g
	>
</svg>
