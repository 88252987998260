import { url } from '$const/website';
import { capitalizeFirstLetter } from './text';

export function asURL(string: string): [URL, null] | [null, Error] {
	try {
		return [new URL(string), null];
	} catch (err) {
		return [null, err as Error];
	}
}

export function slugify(text: string): string {
	return text
		.toLowerCase()
		.replace(/[^\w\s-]/g, '') // Remove non-word characters (except spaces and hyphens)
		.replace(/\s+/g, '-') // Replace spaces with hyphens
		.replace(/^-+|-+$/g, '') // Remove leading/trailing hyphens
		.trim(); // Trim any leading/trailing hyphens
}

export function titlify(text: string): string {
	return `go to ${capitalizeFirstLetter(text)}`;
}

export function ensureTrailingSlash(input: string): string {
	return input.at(-1) === '/' ? input : input + '/';
}

export function ensureStartingSlash(input: string): string {
	return input.at(0) === '/' ? input : '/' + input;
}

export function isExternal(href: string) {
	const absoluteUrlPattern = /^https?:\/\//i;

	if (!href.match(absoluteUrlPattern)) {
		// Relative or protocol-relative URL, consider it internal
		return false;
	}

	// Check if the URL starts with the same base URL to determine if it's internal
	return !href.startsWith(url);
}
