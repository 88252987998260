export type RegisteredTheme = (typeof themes)[number];

export const localhostKey = 'theme' as const;

// https://daisyui.com/docs/themes/
export const themes = [
	// 'light',
	// 'dark',
	// 'cupcake',
	// 'bumblebee',
	'emerald',
	// 'corporate',
	'synthwave',
	// 'retro',
	// 'cyberpunk',
	// 'valentine',
	// 'halloween',
	// 'garden',
	// 'forest',
	// 'aqua',
	// 'lofi',
	// 'pastel',
	// 'fantasy',
	// 'wireframe',
	// 'black',
	// 'luxury',
	// 'dracula',
	// 'cmyk',
	// 'autumn',
	// 'business',
	// 'acid',
	// 'lemonade',
	'night'
	// 'coffee',
	// 'winter'
] as const;

export const fallbackLightKey: RegisteredTheme = 'emerald';
export const fallbackDarkKey: RegisteredTheme = 'night';
