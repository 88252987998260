import { dev } from '$app/environment';
import { BASE_URL } from '$env/static/public';

export const title = 'ShipRapid';
export const description = 'The SvelteKit boilerplate up and running startup in matter of minutes.';
export const url = dev ? 'http://localhost:5173' : BASE_URL;
export const twitterHandle = '@L13o';
export const keywords = [
	'SvelteKit',
	'SvelteKit boilerplate',
	'Svelte',
	'Stripe',
	'Stytch',
	'MongoDB',
	'Mongoose',
	'TypeScript'
];
export const image = new URL('og_image.png', url).toString();
